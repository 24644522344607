<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card title="LBLBASEINFO" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <c-btn 
                v-if="editable && !disabled && popupParam.buyRequestId" 
                :url="completeUrl"
                :isSubmit="isComplete"
                :param="data"
                :mappingType="completeType"
                label="구매신청완료"
                icon="save"
                @beforeAction="completeData"
                @btnCallback="completeCallback" 
              />
              <c-btn v-if="editable && !disabled && popupParam.buyRequestId" label="LBLREMOVE" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
              <c-btn
                v-if="editable && !disabled"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="saveType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-3">
              <c-plant
                :required="true"
                :disabled="disabled"
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="data.plantCd"
              />
            </div>
            <div class="col-3">
              <c-datepicker
                :required="true"
                label='구매신청일'
                default="today"
                :disabled="disabled"
                :editable="editable"
                name="requestDate"
                v-model="data.requestDate"
              />
            </div>
            <div class="col-3">
              <c-dept
                :required="true"
                label='신청부서'
                :disabled="disabled"
                :editable="editable"
                name="requestDeptCd"
                v-model="data.requestDeptCd"
              />
            </div>
            <div class="col-3">
              <c-field
                :required="true"
                label='구매신청자'
                :disabled="disabled"
                :editable="editable"
                name="requestUserId"
                v-model="data.requestUserId"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="신규 보호구 구매 신청 목록"
        tableId="table"
        :columns="grid.columns"
        :data="data.detailList"
        :merge="grid.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        :editable="editable&&!disabled"
        gridHeight="600px"
        selection="multiple"
        rowKey="buyRequestDetailId"
        :plantCd="data.plantCd"
        @table-data-change="tableDataChange"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <c-btn v-if="editable&&!disabled" label="LBLADD" icon="add" @btnClicked="addRow" />
              <c-btn v-if="editable&&!disabled" label="LBLEXCEPT" icon="remove" @btnClicked="removeRow" />
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-gear-buy-request-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        buyRequestId: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        plantCd: null,
        requestDate: '',
        requestDeptCd: '',
        requestUserId: '',
        statusCd: '',

        detailList: [],
        deleteDetailList: [],
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'safetyGearName',
            field: 'safetyGearName',
            label: '보호구명',
            style: 'width:300px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: 'requestCount',
            field: 'requestCount',
            label: '신청수량',
            style: 'width:80px',
            align: 'right',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'safetyGearPrice',
            field: 'safetyGearPrice',
            label: '단가(원)',
            style: 'width:100px',
            align: 'right',
            type: 'number',
            sortable: false,
          },
          {
            name: 'totalPrice',
            field: 'totalPrice',
            label: '총 금액(원)',
            style: 'width:80px',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '보호구 사진',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'NEW_SAFETY_GEAR',
            keyText: 'buyRequestDetailId',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: 'LBLREMARK',
            style: 'width:300px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      disabled: false,
      saveUrl: 'transactionConfig.sop.sas.buy.insert.url',
      saveType: 'POST',
      isSave: false,
      completeUrl: 'transactionConfig.sop.sas.buy.update.url',
      completeType: 'PUT',
      isComplete: false,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.sas.buy.get.url;
      this.insertUrl = transactionConfig.sop.sas.buy.insert.url;
      this.updateUrl = transactionConfig.sop.sas.buy.update.url;
      this.deleteUrl = transactionConfig.sop.sas.buy.delete.url;

      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.buyRequestId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.buyRequestId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data

          if (_result.data.statusCd === 'SBS0000005') {
            this.disabled = true;
          }
        },);
      } else {
        this.data.requestUserId = this.$store.getters.user.userId
      }
    },
    addRow() {
      this.data.detailList.splice(0, 0, {
        buyRequestDetailId: uid(),
        buyRequestId: this.popupParam.buyRequestId,
        safetyGearName: '',
        requestCount: 0,
        safetyGearPrice: 0,
        remarks: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C'
      })
    },
    saveData() {
      if (this.popupParam.buyRequestId) {
        this.data.chgUserId = this.$store.getters.user.userId
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.data.regUserId = this.$store.getters.user.userId
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      if (this.data.detailList.length == 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '신규 구매 신청할 보호구를 추가하세요.', // 신청할 보호구를 하나 이상 등록하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.grid.columns, this.data.detailList)) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGSAVE', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.$set(this.data, 'statusCd', 'SBS0000001')
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.buyRequestId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.buyRequestId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.grid.columns, this.data.detailList)) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: '신청완료 하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.chgUserId = this.$store.getters.user.userId
                this.$set(this.data, 'statusCd', 'SBS0000005')
                this.completeUrl = this.updateUrl;
                this.completeType = 'PUT';
                this.isComplete = !this.isComplete;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.popupParam.buyRequestId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.disabled = true;
      this.getDetail();
    },
    removeRow() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.data.deleteDetailList) {
          this.data.deleteDetailList = []
        }
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.data.deleteDetailList, { buyRequestDetailId: item.buyRequestDetailId }) === -1
            && item.editFlag !== 'C') {
              this.data.deleteDetailList.push(item)
          }
          this.data.detailList = this.$_.reject(this.data.detailList, item)
        });
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    tableDataChange(props) {
      if (Number(props.row['safetyGearPrice']) > 0 && Number(props.row['requestCount']) > 0)  {
        this.$set(props.row, 'totalPrice', Number(props.row['requestCount']) * Number(props.row['safetyGearPrice']))
      } else {
        this.$set(props.row, 'totalPrice', 0)
      }
    }
  },
};
</script>
